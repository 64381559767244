.reasons {
  display: flex;
  padding: 0 2rem;
  gap: 2rem;
}

.left-r {
  flex: 1 1;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 1fr;
  gap: 1rem;
}

.left-r > img {
  object-fit: cover;
}

.left-r > :nth-child(1) {
  width: 12rem;
  grid-row: 1/3;
  height: 28rem;
}

.left-r > :nth-child(2) {
  width: auto;
  height: 16rem;
  grid-column: 2/4;
}

.left-r > :nth-child(3) {
  width: 14rem;
  grid-column: 2/3;
  grid-row: 2;
}

.left-r > :nth-child(4) {
  width: 10rem;
  grid-row: 2;
  grid-column: 3/4;
  height: 11.2rem;
}

.right-r {
  flex: 1 1;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.reasons-text {
  color: var(--orange);
  font-weight: bold;
}

.right-r > div {
  color: #fff;
  font-weight: bold;
  font-size: 3rem;
}

.details-r {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.details-r > div {
  display: flex;
  align-items: center;
  font-size: 1rem;
  gap: 1rem;
}

.details-r > div > img {
  width: 2rem;
  height: 2rem;
}

.details-partners {
  color: var(--gray);
  font-weight: normal;
}

.partners {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.partners > img {
  width: 2.5rem;
}

@media screen and (max-width: 768px) {
  .reasons {
    flex-direction: column;
  }

  .left-r {
    grid-auto-rows: auto;
    overflow: hidden;
  }

  .left-r > :nth-child(1) {
    width: 7rem;
    height: 17rem;
  }

  .left-r > :nth-child(2) {
    width: 15rem;
    height: 10rem;
  }
  .left-r > :nth-child(3) {
    width: 8rem;
  }
}
